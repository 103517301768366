import React, { lazy } from 'react';

import Login from "./pages/Login";

import AppLayout from "./pages/Layout/AppLayout";
import LoginLayout from "./pages/Layout/LoginLayout";

import ChangePassword from "./pages/ChangePassword";
import Notification from "./pages/Notification";
import UserSettings from "./pages/User/UserSettings";

const DomainList = lazy(() => import ("./pages/Sites/DomainList/DomainList"));

const SitesPositionKeyword = lazy(() => import ("./pages/Sites/SitePositionKeyword/SitesPositionKeyword"));
const SitesPosition = lazy(() => import ("./pages/Sites/SitesPosition"));
const SitesDomainPosition = lazy(() => import ("./pages/Sites/SitesDomainPosition"));

const DnsUpdate = lazy(() => import ("./pages/DnsUpdate"));
const RuleList = lazy(() => import ("./pages/CloudFlare/Waf"));
const Proxies = lazy(() => import ("./pages/Crawler/Proxies"));
const DomainStatus = lazy(() => import ("./pages/DomainStatus"));

const CloudflareStatCategory  = lazy(() => import ("./pages/CloudFlare/Stat/CloudflareStatCategory"));

const Settings = lazy(() => import ("./pages/Settings/Settings"));

const ApiWrapperContextProvider = lazy(() => import ("./context/ApiWrapperContext"));
const ModalContextProvider = lazy(() => import ("./context/ModalContext"));
const MultiModalContextProvider = lazy(() => import ("./context/MultiModalContext"));

const Boilerplate = lazy(() => import ("./pages/Boilerplate"));

const Sites = lazy(() => import ("./pages/Sites"));
const Dashboard = lazy(() => import ("./pages/Dashboard"));
const UserList = lazy(() => import ("./pages/User"));

// Editor
const EditorPageView = lazy(() => import ("./pages/Editor/EditorPageView"));
const EditorPageList = lazy(() => import ("./pages/Editor/EditorPageList"));
const EditorFieldProvider = lazy(() => import ("./pages/Editor/context/EditorFieldProvider"));
const EditorPresetList = lazy(() => import ("./pages/Editor/EditorPresetList"));

// Profiles
const Registrars = lazy(() => import ("./pages/Registrars/Registrars"));
const Servers = lazy(() => import ("./pages/Servers/Servers"));
const CloudFlare = lazy(() => import ("./pages/CloudFlare/Profiles"));
const Group = lazy(() => import ("./pages/CloudFlare/Group"));

const routes = [
    {
        to: "/login",
        element: <LoginLayout/>,
        isPrivate: false,
        children: [
            {
                to: "",
                element: <Login/>
            },
        ]
    },
    {
        to: "/",
        element: <AppLayout/>,
        isPrivate: true,
        children: [
            {
                to: "/",
                element: <Dashboard/>,
            },
            {
                to: "/domain/dns-update",
                element: <DnsUpdate/>,
            },
            {
                to: "/domain/rkn-status",
                element: <DomainStatus/>,
            },
            {
                to: "/boilerplate",
                element: (
                    <ApiWrapperContextProvider key='boilerplate' route='boilerplate'>
                        <ModalContextProvider>
                            <Boilerplate/>
                        </ModalContextProvider>
                    </ApiWrapperContextProvider>
                ),
            },
            {
                to: "/sites",
                element: (
                    <ApiWrapperContextProvider key='sites.category' route='sites.category' routeParams={{ paginator: 'without' }}>
                        <ModalContextProvider>
                            <MultiModalContextProvider>
                                <Sites/>
                            </MultiModalContextProvider>
                        </ModalContextProvider>
                    </ApiWrapperContextProvider>
                )
            },
            {
                to: "/sites/positions",
                element: (
                    <ApiWrapperContextProvider key='sites.category' route='sites.category' routeParams={{ paginator: 'without' }}>
                        <ModalContextProvider>
                            <SitesPosition/>
                        </ModalContextProvider>
                    </ApiWrapperContextProvider>
                )
            },
            {
                to: "/sites/:domain/positions",
                element: <SitesDomainPosition/>
            },
            {
                to: "/sites/positions/keyword",
                element: (
                    <ApiWrapperContextProvider key='sites.category' route='sites.category' routeParams={{ paginator: 'without' }}>
                        <ModalContextProvider>
                            <SitesPositionKeyword/>
                        </ModalContextProvider>
                    </ApiWrapperContextProvider>
                )
            },
            {
                to: "/cloudflare/waf",
                element: (
                    <ModalContextProvider>
                        <RuleList/>
                    </ModalContextProvider>
                )
            },
            {
                to: "/cloudflare/stat/category",
                element: <CloudflareStatCategory/>
            },
            {
                to: "/sites/domains",
                element: (
                    <ApiWrapperContextProvider key='site.domains' route='site.domains'>
                        <DomainList/>
                    </ApiWrapperContextProvider>
                )
            },
            {
                to: "/users",
                element: (
                    <ApiWrapperContextProvider key='user.list' route='user.list'>
                        <ModalContextProvider>
                            <UserList/>
                        </ModalContextProvider>
                    </ApiWrapperContextProvider>
                )
            },
            {
                to: "/cloudflare",
                element: <CloudFlare/>
            },
            {
                to: "/cloudflare/group",
                element: <Group/>
            },
            {
                to: "/proxies",
                element: <Proxies/>
            },
            {
                to: "/registrars",
                element: <Registrars/>
            },
            {
                to: "/servers",
                element: <Servers/>
            },
            {
                to: "/settings",
                element: <Settings/>
            },
            {
                to: "/user-settings",
                element: <UserSettings/>
            },
            {
                to: "/change-password",
                element: <ChangePassword/>
            },
            {
                to: "/notification",
                element: <Notification/>
            },
            {
                to: "/editor/preset",
                element: <EditorPresetList/>
            },
            {
                to: "/editor/preset/:presetId/page-list",
                element: <EditorPageList/>
            },
            {
                to: "/editor/test-field-page",
                element: <EditorFieldProvider>
                    <EditorPageView/>
                </EditorFieldProvider>
            }
        ]
    }
];

export default routes
